const Footer = () => {
  return (
    <footer className="page-footer grey darken-3 ">
      {/* grey darken-3 */}
      {/* <div class="container ">
        <div class="row">
          <div class="col l6 s12">
            <h5 class="white-text">Contact</h5>
            <p class="grey-text text-lighten-4">
              Martin Werminghausen
              <br />
              Engineer
              <br />
              Professional Experience: Maybach/MTU/Mercedes in Germany
              <br />
              Registered Architect
              <br />
              Location:
              <br />
              141 South Great Rd.
              <br />
              Lincoln, MA 01773, USA
              <br />
              600AirSuspension@gmail.com
            </p>
          </div>
          <div class="col l4 offset-l2 s12">
            <h5 class="white-text">Links</h5>
            <ul>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  Link 1
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  Link 2
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  Link 3
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  Link 4
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="centerfot">
          © 2022 600AirSuspension
          {/* <a class="grey-text text-lighten-4 right" href="#!">More Links</a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
