import Button from "@mui/material/Button";
import airsuspensionlogo from "../../public/600-airsuspension.jpg";
import Banner from "react-js-banner";
import Emailform from "../AirSuspension/Emailform";

// import ASimages from "../AirSuspension/AS-images.js";
import KHimages from "../600Comhyd/KH-images";

function ComfortHydraulics(props) {
  return (
    <div className="home">
      <Banner
        title="New Pricing Valid January 2024"
        visibleTime={100000}
        css={{
          color: "#FFF",
          backgroundColor: "orange",
          opacity: 0.7,
          fontSize: 18,
          margin: 0,
          height: 25,
          padding: 25,
          // borderRadius: 10,
        }}
      />
      <div id="wrap">
        <div class="content-tab air-suspension active">
          <div className="getFlexed">
            <img
              className="logo"
              src={airsuspensionlogo}
              alt="logo"
              title="logo"
              // style="width:200px;"
            />
          </div>
          <b className="boldbig">For replacement parts, please visit:</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            style={{
              borderRadius: 5,
              backgroundColor: "#ffa722",
              padding: "6px 6px",
              float: "center",
            }}
            className="ButtonAir"
            variant="contained"
            size="large"
            fontSize="bold"
            target="_blank"
            rel="noreferrer"
            href="https://600airsuspensionshop.com/collections/600-comfort-hydraulics-kh"
          >
            600 AIR SUSPENSION SHOP
            {/* <i className="material-icons">shopping_cart</i> */}
          </Button>
        </div>
        <h2>
          <b>Mercedes 600 Komfort Hydraulik Repair Services</b>
        </h2>
        <hr />
        <h3>The ‘Komfort Hydraulik’ System Serviced</h3>
        <p>
          We are offering professional renovation for core parts of the Mercedes
          600 (Mercedes 600 from 1963 to1981) comfort hydraulic or KH system
          (German: Komfort Hydraulik) with Bosch or Barmag pump, Teves (ATE)
          Piston Accumulator and Connectors, Switches and Valves as well as
          Pistons. Basically all parts of the system that may leak can be
          serviced, rebuilt and pressure tested.
        </p>
        <h3>A very Unique System</h3>
        <p>
          You might think the Mercedes 600 hydraulics is a description of an
          aircraft system… but it is not. This is a support system in a car and
          probably the most unique, expensive and sophisticated system offered
          in a car.{" "}
        </p>
        <p>
          The 600 models were designed with a hydraulic system that surpassed
          the luxury and elegance of other cars by far. The system is very
          reliable but with age the rubber and pressure connections, switch
          valves and piston seals start to leak which causes a lot of grief for
          the 600 owners.
        </p>
        <p>
          The comfort hydraulic system is probably the most unique feature of
          the legendary Mercedes 600 but also one of most feared ones. If any of
          the parts fail it will lead to problems that can be minor like a seat
          or a window moving slowly by itself or pump or accumulator failing
          which is leading to uncomfortable situations like potentially
          releasing the hydraulic door latches during driving. This will not
          lead to any great risk because the door will not open during driving,
          but it might feel a little strange. The most common problems are oil
          leaks to the inside of the car, which is annoying and cannot be
          tolerated. There might be other internal leakage in a switch or
          piston, which results in a loss of pressure but no external leaks.
        </p>
        <h3>Maintenance and Repair</h3>
        <p>
          The Mercedes 600 hydraulic system failure requires attention and
          proper maintenance. The repair can be overwhelming and rebuilding the
          units by a qualified specialist does not come cheap. A hobbyist cannot
          do the repairs and Bosch did not foresee any DIY repair, not even the
          change of pressure port seals. Any repaired units must be pressure
          tested on a bench or test stand, the pressure pumps need to run on a
          test stand for proper adjustment.
        </p>
        <h3>Our Work</h3>
        <p>
          We work with compassion, integrity and honesty. We share our knowledge
          with the community and we like collaborating. We use the best
          technology and craftsmanship available. CNC parts are ‘Made in
          Germany’ and we use high quality custom seal rings and other materials
          like Teflon or Delrin for custom seals or valve bodies. Depending on
          the situation custom designs might be required. All repaired parts and
          units run through testing. We are giving a 2-year warranty for
          tightness and function within specification for units and parts. You
          are requested to send your units for rebuilding before we start and we
          will rebuild the units within ca. 2-4 weeks of turn-around or upon
          request. Please ask for a turn-around time if the item is time
          sensitive. Prices are based on the assumption that provided cores are
          repairable which is typically the case. We will keep our clients
          informed about the process and communicate.{" "}
        </p>
        {/* <p>
          Bosch and Barmag pumps will be tested on a dynamic test stand to
          confirm functions and correct pressure settings (175 and 200 bar).
        </p> */}
        <h3>What is done during Renovation?</h3>
        <h4>Bosch and Barmag Pumps:</h4>
        <p>
          Disassembly, all parts cleaned, unit body cleaned, surfaces and
          threads check and recut/corrected, steel parts zinc plated/ chromate,
          seals exchanged, new ball bearings, new bronze bearings if needed,
          adjustments according MB specification, static pressure testing for
          pressure check valve and over pressure valve, dynamic pressure on test
          stand for pressure build up and output pressure adjustment 175/200bar.
          New paint and steel parts are yellow plated. Barmag pumps often
          require new bridge seal and suction check valve.
        </p>
        <h3>Teves Piston Accumulator:</h3>
        <p>
          Disassembly, all parts cleaned, unit body cleaned, steel parts will be
          zinc plated/ chromate, Piston replaced with new-machined part and
          modern accumulator seal, seals exchanged, new high pressure Schrader
          valve. Nitrogen charge 100 bar, pressure testing. Check valve
          dis-assembled, lapped and pressure tested. The diaphragm accumulator
          cannot be serviced.
        </p>
        <h3>Switches</h3>
        <p>
          Disassembly, all parts cleaned, unit body cleaned and repaired, bores
          reamed, surfaces corrected, inner valves inspected/changed, new
          CNC-machined rings and seals, adjustments according MB spec, pressure
          testing. Damaged levers exchanged.
        </p>
        <h3>Pistons</h3>
        <p>
          There are single and double acting piston und a variety of
          sizes/styles used in the KH system. We service all of them. Mostly the
          piston seals are fine but leaking pressure ports need to be rebuilt.
          In case the piston seals are damaged-the problem can be analyzed
          during pressure testing- the unit can be opened and the piston seal
          exchanged, pressure testing, new pressure ports.
        </p>
        <p>
          Initial pressure testing and analysis, disassembly, parts cleaned,
          unit body cleaned, damaged parts exchanged, new pressure ports, final
          pressure testing, paint.
        </p>
        <h3>Connectors and Shut-off Valve</h3>
        <p>
          Disassembly, all parts cleaned, seal and seal rings exchanged,
          pressure ports rebuilt, pressure tested.
        </p>
        <h3>Pressure Switch</h3>
        <p>
          Disassembly, all parts cleaned, seal and seal rings exchanged,
          pressure ports rebuilt, pressure tested. Not all cars have the
          pressure switch.
        </p>
        <h3>Assemblies</h3>
        <p>
          Assemblies are e.g. the seat unit, door latch, trunk unit or center
          dash unit or climate unit (hydraulically) consist of a combination of
          hydraulic parts. We service all the assemblies used in a Mercedes 600.
        </p>
        <p>Please do not hesitate and ask questions.</p>
        <h3>Articles</h3>
        <ul>
          <li>
            <a
              href="http://600airsuspension.com/resources/190830-Article-Mercedes-600-Comfort-Hydraulics.pdf"
              target="_blank"
              rel="noreferrer"
              alt="article"
              title="Article"
            >
              Mercedes 600 Comfort Hydraulics by Martin Werminghausen
            </a>
            <div className="redd">*Updated Aug 30, 2019</div>
          </li>
        </ul>
        <h3>Related Images</h3>
        <center>
          <b>
            <u>Scroll Through Images</u>
          </b>
        </center>
        <KHimages />
        <hr />
        <p>
          Prices for rebuilding Mercedes 600 comfort hydrailic units to be sent
          for rebuilding
        </p>
        <table className="highlight bordered">
          <tbody>
            <tr>
              <td>Hydraulic Pump Bosch or Barmag, complete rebuilding</td>
              <td>$1,465</td>
            </tr>
            <tr>
              <td>Piston accumulator ATE/Teves </td>
              <td>$1,587</td>
            </tr>
            <tr>
              <td>4- way connector </td>
              <td>$329</td>
            </tr>
            <tr>
              <td>3- way connector </td>
              <td>$260</td>
            </tr>
            <tr>
              <td>2- way connector </td>
              <td>$188</td>
            </tr>
            <tr>
              <td>1- lever switch, level excluded </td>
              <td>$575</td>
            </tr>
            <tr>
              <td>1- lever switch w/ throttle, lever excluded </td>
              <td>$635</td>
            </tr>
            <tr>
              <td>2-lever switch . lever excluded, </td>
              <td>$763</td>
            </tr>
            <tr>
              <td>3- lever switch, lever excluded </td>
              <td>$952</td>
            </tr>
            <tr>
              <td>4- lever switch, lever excluded </td>
              <td>$1,120</td>
            </tr>
            <tr>
              <td>Shut-off valve </td>
              <td>$329</td>
            </tr>
            <tr>
              <td>Single acting ram, inner piston seals excluded </td>
              <td>$117</td>
            </tr>
            <tr>
              <td>Double acting ram , inner piston seals excluded </td>
              <td>$212</td>
            </tr>
            <tr>
              <td>
                Hydraulic Oil Filter Kit , replacement for original filter{" "}
              </td>
              <td>$56</td>
            </tr>
            <tr>
              <td>KH Pressure Switch 130 bar</td>
              <td>$517</td>
            </tr>
            <tr>
              <td>KH Accumulator Check Valve</td>
              <td>$141</td>
            </tr>
            <tr>
              <td>
                KH DA 20mm Window Piston: inner seals $764 plus pressure ports
                $212
              </td>
              <td>$976 together</td>
            </tr>
            <tr>
              <td>
                KH DA 11mm Seat Piston and Sunroof Piston: inner seals $764 plus
                pressure ports $212
              </td>
              <td>$976 together</td>
            </tr>
            <tr>
              <td>
                KH SA 20mm Piston, : inner seals $564 plus pressure port $118
              </td>
              <td>$682 together</td>
            </tr>
            <tr>
              <td>
                KH SA 10mm Latch Piston: inner seals $446 plus pressure port
                $118
              </td>
              <td>$564 together</td>
            </tr>
            <tr>
              <td>
                KH SA 13.5mm Vent Piston: inner seals $446 plus pressure port
                $118
              </td>
              <td>$564 together</td>
            </tr>
          </tbody>
        </table>
        <h3>Contact</h3>
        <p>Send me an email or fill out the contact form to get in touch</p>
        <div className=" contactinfo">
          <div className="row">
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                {/* <div className="contactinfo"> */}
                <p className="notp">
                  Martin Werminghausen
                  <br />
                  Engineer
                  <br />
                  Professional Experience: Maybach/MTU/Mercedes in Germany
                  <br />
                  Registered Architect
                  <br />
                  Location:
                  <br />
                  141 South Great Rd.
                  <br />
                  Lincoln, MA 01773, USA
                  <br />
                  <br />
                  Email:{" "}
                  <a href="mailto:600AirSuspension@gmail.com">
                    600AirSuspension@gmail.com
                  </a>
                  {/* <div className="buttons">
                    <Button
                      style={{
                        borderRadius: 2.5,
                        fontSize: "14px",
                        float: "center",
                        marginTop: "2.5%",
                      }}
                      className="contactbut btn blue"
                      variant="contained"
                      href="mailto:600AirSuspension@gmail.com"
                    >
                      Contact
                    </Button>
                  </div> */}
                  <br />
                </p>
              </div>
            </div>
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                <center>
                  <div className="submitted"></div>
                </center>
                <div className="hidemail">
                  <Emailform />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- <button class="contact"><a href="mailto:600AirSuspension@gmail.com">Contact</a></button> --> */}
        <br />
        <br />
        <h3>Reviews</h3>
        <br />
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Sean Mansfield, South Africa
                </h5>
                I would like to thank you for the efficient and professional
                service that I received from you. The parts have all been fitted
                now and what a pleasure to have a 600 with no hydraulic leaks.
                It is always a worry when parts go half way across the world to
                be repaired as to what the result will be, well, I can assure
                you that I am a really happy and satisfied customer. Thank you
                again. Wishing you well from sunny South Africa, kind regards
                Sean Mansfield.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Ruud, Netherlands
                </h5>
                It has been a long time, but I guess one shouldn't rush fixing
                the hydraulics of a 600.. But finally, finally my car holds
                pressure for a long time. And to be honest, not so much due to
                my skills, but for the most part due ot the interventions of Mr.
                Werminghausen. Some of you may remember this old thread, that
                started with problem that in approximately three or four days
                all hydraulic oil would flow back to the reservoir - including
                the oil from the accumulator. So every time I would take the car
                for a drive, it had to repressurize. For way too long I tried to
                find the element(s) with an internal leak ‘downstream’ as Mr.
                Werminghausen calls it. That is, behind the shut-off valve. But
                whatever I tried (the book describes some testing procedures),
                it never prevented the reservoir from being filled after a
                while. In the end I even tried to block the return hose
                completely - still the same problem! Then I remembered an almost
                forgotten suggestion that Mr. Werminghausen made months earlier.
                That is, to suspect the check-valves of the pump and the
                accumulator. So oil would not (only) flow back from the
                elements, but directly from the accumulator, using the supply
                hose. Finally it all made sense. But by that time the
                reprssurizing of the system started to take longer and started
                to act erratic. Just when I was wondering wether to remove both
                the pump and the accumulator (I always get nervous shipping
                parts), the accumulator seemed to have broken down completely,
                not being able to hold any pressure. Things got from bad to
                really worse. I discussed the repair of both the pump and the
                accumulator with Mr. Werminghausen. He strongly suggested to add
                a hydraulic gauge in the system, that would make it possible to
                closely monitor the system. Fortunately I had a spare flexible
                hydraulic line. After Mr. Werminghausen sent a gauge, I attached
                it to a spare connection of the shut-off valve. In the mean time
                I shipped both the pump (Bermag) and the accumulator to Mr.
                Werminghausen, who has developed a dedicated test bench for the
                pump. Both items were in desperate need for some attention, and
                the accumulator was even clogged with big chunks of dirt that
                may be deteriarated rubber. The hydraulic system really needs to
                be clean on order to keep working. Well, my parts were in a
                different condition - to say the least. Mr. Werminghausen
                completely overhauled both the pump and the accumulator, using
                new designed parts and even a new piston for the accumulator.
                The re-fitting of the pump and the accumulator is not too
                complicated. After starting the engine I immediately noticed the
                pressure build up by the gauge, that ended at exactly 200 bar.
                It has been quite a long adventure, but I finally feel that I am
                in control of the hydraulic system, and not the other way
                around. Much to the credit of Mr. Werminghausen to be honest,
                who suggested to add a permanent hydraulic gauge. I just feels
                great to really see how the sytem is behaving, in real time. No
                more guessing and assumptions. I do not own any shares in Mr.
                Werminghausens company :-) so I am at liberty to really
                recommend him for both the air suspension and you hydraulic
                parts. Besides the perfect job he is doing, he is always there
                to answer questions or share thoughts. Anyway, here is the setup
                of the guage:{" "}
                <a href="http://www.m-100.cc/forum/topic.asp?TOPIC_ID=5856&whichpage=3">
                  http://www.m-100.cc/forum/topic.asp?TOPIC_ID=5856&whichpage=3
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Dan, Tacoma WA
                </h5>
                1966 Mercedes Benz Grand 600 @36K miles
                <br />
                <br />
                Current work: Hydraulics
                <br />
                <br />
                My partner [Bradley] I have been long time vintage car
                collectors/restorers . With the purchase of the Grand 600 we new
                something different was coming our way.
                <br />
                The 600, while in great original shape, had hydraulic issues
                that left us scratching our heads. Starting with online searches
                for specific repairs we soon realized there was little info or
                <br />
                service to be found. Eventually winding up on M100 forum, we
                followed a trail to 600Airsuspension and finally, Martin
                Werminghausen!
                <br />
                <br />
                Martin has successfully rebuilt: Accumulator, hydraulic pump,
                door glass regulator pistons, auto door latch elements, window
                switch, a dozen or so junction blocks,control levers for
                sunroof/cowl vent and
                <br />
                most recently the master cylinder. Also, Martin has provided a
                hydraulic pressure gauge that adapts to the O.E.M. system, which
                helped in restoring the hydraulics of the Grand 600.
                <br />
                I'm happy to report that the hydraulic system achieves factory
                specs of over 3000 psi, and will maintain over 2000 psi for
                several weeks while garaged. All rebuilt working components
                continue to function smoothly,
                <br />
                and best of all NO HYDRAULIC OIL LEAKS!
                <br />
                <br />
                Martin is genuinely concerned with his work and customer
                satisfaction; we would not hesitate to recommend him for repairs
                on this complex car.
                <br />
                <br />
                Thank you Martin!
              </span>
            </div>
          </div>
        </div>

        <br />
        <h3>Return shipment of parts</h3>
        <p>
          These are valuable vintage parts. Cores received by the client for
          rebuilding are owned by the client, also for the duration of repairs.
          In case of a lost return box we cannot be made responsible for the
          loss of cores.
          <br />
          Please accept that we insure return shipment with an estimated core
          value plus rebuilding charges, in
          <br />
          agreement with the client.
          <br />
          The client may instruct us to ship with less insurance at client’s
          risk.
        </p>
      </div>
    </div>
  );
}

export default ComfortHydraulics;
