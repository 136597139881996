import Button from "@mui/material/Button";
import airsuspensionlogo from "../../public/600-airsuspension.jpg";
import Emailform from "../AirSuspension/Emailform";
import Banner from "react-js-banner";

import HPFimages from "../6.9hpf/HPF-images.js";
import pdf1 from "../../public/pdf/Sherlock_Axle_Drop_Log_HPF.pdf";

function Hpf(props) {
  return (
    <div className="home">
      <Banner
        title="New Pricing Valid January 2024"
        visibleTime={100000}
        css={{
          color: "#FFF",
          backgroundColor: "orange",
          opacity: 0.7,
          fontSize: 18,
          margin: 0,
          height: 25,
          padding: 25,
          // borderRadius: 10,
        }}
      />
      <div id="wrap">
        <div class="content-tab air-suspension active">
          <div className="getFlexed">
            <img
              className="logo"
              src={airsuspensionlogo}
              alt="logo"
              title="logo"
              // style="width:200px;"
            />
          </div>
          <b className="boldbig">For replacement parts, please visit:</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            style={{
              borderRadius: 5,
              backgroundColor: "#ffa722",
              padding: "6px 6px",
              float: "center",
            }}
            className="ButtonAir"
            variant="contained"
            size="large"
            fontSize="bold"
            target="_blank"
            rel="noreferrer"
            href="https://600airsuspensionshop.com/collections/hydraulic-suspension-hpf"
          >
            600 AIR SUSPENSION SHOP
            {/* <i className="material-icons">shopping_cart</i> */}
          </Button>
        </div>
        <h2>
          <b>
            Mercedes W116, 6.9 and W126 HPF Hydraulic Suspension Repair Services
          </b>
        </h2>
        <hr />
        <h3>The ‘HPF’ system serviced</h3>
        <p>
          We are offering professional renovation for core parts of the Mercedes
          W116 6.9 HPF and W 126 HPF 1 and 2 (German: Hydro-Pneumatische
          Federung) which include pressure pump, pressure regulator, accumulator
          (5th sphere), switch unit, axle valve, HPF 2 pull rod and four struts
          (HPF 1 and 2) with spheres (gas springs). All parts of the system can
          be serviced.
        </p>
        <h3>HPF- The ‘New’ Air Suspension</h3>
        <p>
          Mercedes invented a variation of the air suspension involving
          hydraulics. The HPF is the successor of the original Air Suspension of
          the W112, W109 and the 600 cars.
        </p>
        <p>
          The new system used smaller sized units for the pressure pump,
          regulator, lines etc. This was possible due to (10 times)
          higher-pressure levels of the hydraulics. Pressures are up to 200bar
          in the HPF system relative to 20 bar air pressure used for the air
          suspension system. The hydraulic system also has the advantage that
          hydraulic rubber seals last longer and the metal valve seats are no
          longer depending on rubber inside the axle valve. Another advantage
          was a seamless integration of the strut into the hydraulic system.
          This made the HPF system as good as the air suspension from its
          suspension qualities but also relatively robust and reliable.
        </p>
        <p>
          However as the HPF comes to age the rubber and Teflon seals are
          failing and metal seats can wear out leading to complaints about
          external and internal leaks, similar to the original air suspension.
          Nitrogen accumulators were used as gas springs (working with 80-100
          bar spring pressure) and with age the diaphragm of the accumulator
          starts leaking and finally fails. Accumulators should be tested every
          couple of years and changed if gas pressure drops below threshold.
        </p>
        <p>
          The hydraulic repairs are quite different from the former air
          suspension and the system has a similar complexity. Oil however cannot
          escape unseen as does air. Oil leaks on the ground are witnesses of a
          leaking system begging for repair.
        </p>
        <p>
          <b>
            SLS Suspension Repairs for the rear suspension support for many
            Mercedes cars are in development and can be offered in the near
            future:
          </b>
          <br />
          SLS Axle Valve, SLS Strut. The SLS pump can be serviced currently as
          it is the same pump as the HPF pump.
        </p>
        <h3>Maintenance and Repair</h3>
        <p>
          The 6.9 HPF and W126 HPF hydraulic system failures require attention
          and proper maintenance. The repair can be overwhelming and rebuilding
          the units by a qualified specialist does not come cheap. A hobbyist
          cannot do the repairs and Bosch did not foresee any DIY repair except
          for testing pressures probably. Repaired units must be pressure
          tested.
        </p>
        <h3>What we do</h3>
        <p>
          We work with compassion and our products are based on integrity and
          honesty. We share our knowledge with the community and we like
          collaborating. We use the best technology and craftsmanship available.
          We use high quality CNC parts for repair parts and Teflon seals.
          Sometimes custom designs are needed. Teflon seals can be designed and
          machined for special purposes. All repaired parts and units run
          through intensive testing. We are giving a 2-year warranty for
          tightness and function within specification for units and parts. You
          are requested to send your units for rebuilding before we start and we
          will rebuild the units within ca. 2-4 weeks of turn-around or upon
          request. Please ask for a turn-around time. Prices are based on the
          assumption that provided cores are repairable which typically is the
          case. We will keep our clients informed about the process and
          communicate if there is a problem.
        </p>
        <h3>Renovation in Detail</h3>
        <h3>Hydraulic Pump (HPF and SLS Suspension)</h3>
        <p>
          Disassembly, all parts cleaned, unit body cleaned, surfaces and
          threads checked and recut/corrected, steel parts yellow zinc plated,
          seals exchanged, bushings tested for excess play, machined new if
          necessary, new shaft seal, static pressure testing and dynamic testing
          on test stand.
        </p>
        <h3>Diaphragm Accumulator</h3>
        <p>
          TThe original and aftermarket unit can be pressure tested (nitrogen
          charge) but must be discarded in case the gas pressure is below the
          specified threshold.{" "}
        </p>
        <p>
          If the old spheres are Mercedes Benz (stronger body) and technically
          in good order we offer to install a high pressure valve and fill the
          ‘new’ sphere with the specified nitrogen pressure. This sphere can
          then be serviced and be refilled with nitrogen.
        </p>
        <h3>Regulator</h3>
        <p>
          Disassembly, all parts cleaned, unit body cleaned and repaired, bores
          reamed, surfaces corrected, inner valves inspected and lapped, filter
          inspected and cleaned new rubber and Teflon seals, new valve bodies.
          Regulator adjustments according MB specs, pressure testing. Steel
          parts are yellow plated.
        </p>
        <h3>Switch Unit</h3>
        <p>
          The Switch Unit is the aluminum block attached to the Regulator. Inner
          valves repaired, valve seats lapped, new valve bodies and seals
          installed, cam disc tested. Unit pressure tested on test stand. Steel
          parts are yellow plated.
        </p>
        <h3>Axle Valves</h3>
        <p>
          Disassembly, all parts cleaned, Rubber and Teflon seals exchanged,
          intake and exhaust valves restored, new valve bodies, valve fully
          pressure tested, static and dynamic testing . New rubber boot
          installed. Steel parts are yellow plated.
        </p>
        <h3>Axle Valve Pull Rod with Hydraulic Piston (HPF2 -late W126)</h3>
        <p>
          Disassembly, all parts cleaned, Piston seal and seal rings exchanged,
          new boot, pressure tested.
        </p>
        <h3>Struts HPF 1 (6.9, early W126)</h3>
        <p>
          Disassembly, all parts cleaned, Rubber and custom Teflon seals
          exchanged. Strut pressure tested. Steel parts are yellow plated.
        </p>
        <h3>Struts HPF 2 (late W126)</h3>
        <p>
          Disassembly, all parts cleaned, Rubber and custom Teflon seals
          exchanged. Strut pressure tested. Steel parts are yellow plated.
        </p>
        <p>
          HHPF 2 Struts (late W126) have the extra valve for hard/soft
          suspension setting that needs overhaul.
        </p>
        <h3>Pressure Hoses</h3>
        <p>
          Disassembly, yellow plated fittings reused with new pressure hose
          according MB specs. pressure tested.
        </p>
        <h3>Return Hose</h3>
        <p>
          Disassembly, yellow plated fittings reused with new pressure hose
          according MB specs.
        </p>
        <p>
          Return hoses are specitic to car (6.0, W126 early and late) and
          location (front, rear, left/right)
        </p>
        <h3>Sphere, Accumulator</h3>
        <p>Spheres are losing pressure over time.</p>
        <p>
          Old Mercedes Spheres that still hold at least 50% of nitrogen pressure
          can be rebuilt/ converted to a serviceable accumulator. A high
          pressure gas valve will be installed and the sphere be filled with
          nitrogen to the specified pressure. These spheres can be refilled
          later.
        </p>
        <h3>SLS Strut</h3>
        <p>We can offer rebuilding this strut in the new future</p>
        <p>Please do not hesitate and ask questions.</p>
        <h3>Articles</h3>
        <ul>
          <li>
            <a
              href="http://600airsuspension.com/resources/2020_HPF_Sherlock_Tests.pdf"
              target="_blank"
              rel="noreferrer"
              alt="article"
              title="Article"
            >
              2020 HPF Sherlock Tests by Martin Werminghausen
            </a>
            <div className="redd">*Updated June 17, 2020</div>
          </li>
          <li>
            <a
              href={pdf1}
              target="_blank"
              rel="noreferrer"
              alt="article"
              title="Article"
            >
              Sherlock Axle Drop Log HPF
            </a>
          </li>
          <li>
            <a
              href="http://600airsuspension.com/resources/Lodestar_66_martin_werminghausen.pdf"
              target="_blank"
              rel="noreferrer"
              alt="article"
              title="Article"
            >
              M-100 Gas Suspension and the Passage of Time by Martin
              Werminghausen
            </a>
            <div className="redd">*Updated Aug 25, 2019</div>
          </li>
        </ul>
        <h3>Related Images</h3>
        <center>
          <b>
            <u>Scroll Through Images</u>
          </b>
        </center>
        <HPFimages />
        <hr />
        {/* <div class="slide hpf"></div> */}
        <h3>Prices Mercedes W116 6.9 HPF services</h3>
        <p>
          Prices for rebuilding Mercedes W116, 6.9 Units to be sent for
          rebuilding
        </p>
        <table className="highlight bordered">
          <tbody>
            <tr>
              <td>HPF pump, Bosch </td>
              <td>$532</td>
            </tr>
            <tr>
              <td>HPF pressure regulator </td>
              <td>$998</td>
            </tr>
            <tr>
              <td>HPF switch unit (attached to pressure regulator) </td>
              <td>$422</td>
            </tr>
            <tr>
              <td>
                HPF axle valve <br />
                *machining of housing in case of excessive internal wear{" "}
              </td>
              <td>
                $784 <br />
                *$108
              </td>
            </tr>
            <tr>
              <td>HPF2 Pull Rod Piston</td>
              <td>$322</td>
            </tr>
            <tr>
              <td>
                HPF1 strut , ball joints, cups and and rods excluded <br />
                HPF2 struts
              </td>
              <td>
                $998 <br />
                $1,106
              </td>
            </tr>
            <tr>
              <td>HPF Strut Pressure Hose DN10</td>
              <td>$170</td>
            </tr>
            <tr>
              <td>HPF Strut Return Hose 6mm </td>
              <td>$64</td>
            </tr>
            <tr>
              <td>
                Axle Valve Pull Rod with Hydraulic Piston (HPF2 -late W126)
              </td>
              <td>$322</td>
            </tr>
            <tr>
              <td>Sphere , Accumulator rebuilding with gas valve</td>
              <td>$205</td>
            </tr>
            <tr>
              <td>Accumulator refill with Nitrogen</td>
              <td>$45</td>
            </tr>
          </tbody>
        </table>
        <h3>Contact</h3>
        <p>Send me an email or fill out the contact form to get in touch</p>
        <div className=" contactinfo">
          <div className="row">
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                {/* <div className="contactinfo"> */}
                <p className="notp">
                  Martin Werminghausen
                  <br />
                  Engineer
                  <br />
                  Professional Experience: Maybach/MTU/Mercedes in Germany
                  <br />
                  Registered Architect
                  <br />
                  Location:
                  <br />
                  141 South Great Rd.
                  <br />
                  Lincoln, MA 01773, USA
                  <br />
                  <br />
                  Email:{" "}
                  <a href="mailto:600AirSuspension@gmail.com">
                    600AirSuspension@gmail.com
                  </a>
                  {/* <div className="buttons">
                    <Button
                      style={{
                        borderRadius: 2.5,
                        fontSize: "14px",
                        float: "center",
                        marginTop: "2.5%",
                      }}
                      className="contactbut btn blue"
                      variant="contained"
                      href="mailto:600AirSuspension@gmail.com"
                    >
                      Contact
                    </Button>
                  </div> */}
                  <br />
                </p>
              </div>
            </div>
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                <center>
                  <div className="submitted"></div>
                </center>
                <div className="hidemail">
                  <Emailform />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- <button class="contact"><a href="mailto:600AirSuspension@gmail.com">Contact</a></button> --> */}
        <br />
        <br />
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Jordan Hunn, USA
                </h5>
                Before I contacted Martin, my 6.9’s suspension wasn’t quite
                right. Despite new struts and spheres, the front still sagged
                when I parked the car and the suspension pressure light came on
                occasionally. The front leveling valve had been rebuilt by
                another specialist, but my mechanic complained that it didn’t
                seem to be working correctly. I reached out to Martin, who
                tested all of the hydro suspension parts and rebuilt them
                beautifully to spec. My hydraulics are now perfect, and I
                understand just what people mean when they talk about a “magic
                carpet” ride. It’s smooth, controlled, and trouble-free.
                <br />
                <br />
                There are other hydraulic rebuilders out there who claim to
                understand the 6.9 hydro suspension. I believe that Martin is
                the only rebuilder in America who actually does. You will not go
                wrong trusting your 6.9 hydraulic parts to him.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Peter Bloedt, USA, Mercedes-Benz 600 & Classics Specialist
                </h5>
                Hello Martin,
                <br />
                Just wanted to express my sincere gratitude and appreciation for
                your excellent work on the 6.9 regulator valve. I wish you could
                have seen the smile on my client's face, when his beached whale,
                a 1977 6.9, raised its belly for the first time in about eleven
                years of storage! I'm so glad I was referred to you by the
                Classic Center, and look forward to many years of doing business
                with you.
                <br />
                <br />
                Greetings from California,
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <div class="card-panel grey lighten-5">
              <span class="black-text">
                <h5>
                  Review By <br />
                  Tom Merritt, USA
                </h5>
                So, if you want your 6.9 hydro-pneumatic suspension to work
                properly there is really only one person to do it and that is
                Martin Werminghausen. I say that with zero reservation. You just
                could not put yourself in any better hands and that goes for 600
                and 6.3 air suspensions as well. He is truly the epitome of a
                great German engineer and to think he is an international
                architect that solves car problems in his spare time for fun.
                Thanks, Martin!!
              </span>
            </div>
          </div>
        </div>
        <br />
        <h3>Return shipment of parts</h3>
        <p>
          These are valuable vintage parts. Cores received by the client for
          rebuilding are owned by the client, also for the duration of repairs.
          In case of a lost return box we cannot be made responsible for the
          loss of cores.
          <br />
          Please accept that we insure return shipment with an estimated core
          value plus rebuilding charges, in
          <br />
          agreement with the client.
          <br />
          The client may instruct us to ship with less insurance at client’s
          risk.
        </p>
      </div>
    </div>
  );
}

export default Hpf;
