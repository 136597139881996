import Button from "@mui/material/Button";
import airsuspensionlogo from "../../public/600-airsuspension.jpg";
import Emailform from "../AirSuspension/Emailform";
import Banner from "react-js-banner";

import FuelPumpimages from "../FuelPump&More/FuelPump-images.js";
// import { border } from "@mui/system";

function Fuelpumpmore(props) {
  return (
    <div className="home">
      <Banner
        title="New Pricing Valid January 2024"
        visibleTime={100000}
        css={{
          color: "#FFF",
          backgroundColor: "orange",
          opacity: 0.7,
          fontSize: 18,
          margin: 0,
          height: 25,
          padding: 25,
          // borderRadius: 10,
        }}
      />
      <div id="wrap">
        <div class="content-tab air-suspension active">
          <div className="getFlexed">
            <img
              className="logo"
              src={airsuspensionlogo}
              alt="logo"
              title="logo"
              // style="width:200px;"
            />
          </div>
          <b className="boldbig">For replacement parts, please visit:</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            style={{
              borderRadius: 5,
              backgroundColor: "#ffa722",
              padding: "6px 6px",
              float: "center",
            }}
            className="ButtonAir"
            variant="contained"
            size="large"
            fontSize="bold"
            target="_blank"
            rel="noreferrer"
            href="https://600airsuspensionshop.com/collections/fuel"
          >
            600 AIR SUSPENSION SHOP
            {/* <i className="material-icons">shopping_cart</i> */}
          </Button>
        </div>
        <h2>
          <b>Fuel Pumps, PS Pumps and More</b>
        </h2>
        <hr />
        <h3>Classic Mercedes Benz Car Repair Services</h3>
        <p>
          We are happy to offer more services in our repair shop for the Classic
          Mercedes Benz cars from early 60s to mid 80s and other cars.
        </p>
        {/* <h3>Fuel Pumps</h3>
        <p>
          Rebuilding Fuel Pumps for mechanical fuel injection and D-Jet
          electronic fuel injection from the 60s and 70s era with new brushes,
          seals, terminal repair, steel body plated, pressure tested.{" "}
        </p> */}
        <h3>Long Fuel Pump</h3>
        <p>
          Early Style Long Fuel Pump for mechanical fuel injection - M100, M127,
          M129, M130, M189: W111, W112, W113, W114, W107, W108 and W109 Bosch 0
          442 200 002 0 442 200 008, 0 442 200 007, 0 442 200 006 Bosch FP/ESB 5
          RC/12/1 Bosch FP/ESB 5 RC/12/1A1
        </p>
        <p>Mercedes Benz A 001 091 1301 Mercedes Benz A 001 091 4341</p>
        <p>
          100 Chassis 600 Grosser Mercedes - up to chassis ending in 001377 108
          Chassis 250SE, 280SE, 300SEB - up to chassis ending in 028985 109
          Chassis 300SEL,300SEL 6,3 - up to chassis ending in 001937 111 Chassis
          220SEB 112 Chassis 300SE. 300SE/C - early models 113 Chassis 230SL,
          250SL &amp; 280SL - up to chassis ending in 010242
        </p>
        <h3>Short Fuel Pump</h3>
        <p>
          {" "}
          Late Style Short Fuel Pump for mechanical fuel injection replacing
          Long Fuel Pump Bosch 0 442 201 002 , 0 442 201 001 , Mercedes Benz A
          001 091 52 01, A 001 091 71 01
        </p>
        <h3>Early D-Jet Fuel Pump </h3>
        <p>
          For W108 and W109 and other cars (3-Port) Bosch 0 580 970 002 early
          style D-Jet Bosch 0 580 970 002 und 0 580 970 004 Mercedes Benz A 001
          091 74 01
        </p>
        <h3>Late D-Jet Fuel Pump</h3>
        <p>
          For W108 and W109 and other cars (2 –Port) Bosch 0 580 464 006 late
          style D-Jet Mercedes Benz A 001 091 71 01
        </p>
        <h3>Cold Start Valve</h3>
        <p>
          For mechanical fuel injected engines M189 and M100 Rebuilding of Cold
          Start Valve Bosch EP/EV 2/1 - EP/EV 2/5 Mercedes Benz A 000 071 21 37
        </p>
        <h3>
          Citroen 7 Cylinder Hydraulic Pump for Suspension and accessories
        </h3>
        <p>
          Hydraulic Pump for Citroen DS/SM and Maserati Merak Citroen 9S5409647L
        </p>
        <h3>Power Steering Pumps</h3>
        <p>VT27 (Vickers)</p>
        <p>Part Numbers</p>
        <p>
          #115 460 0980 (W114/115, W107, W116.020/ .024/ .025 - 60 and 65 bar),
          <br />
          #127 4600 1280 (W107, W116. 020/.028/ .029/ .03- 65 bar),
          <br />
          # 116 460 1080 (W116.020/ .028/ .029 , W116.032/ .033 - 82 bar) <br />
          #100 460 0280 (W100 -82 bar)
          <br />
          #189 460 0680 ( W112, W109)
        </p>
        <p>Power Steering Pump VT49 (Vickers)</p>
        <p>
          #116 460 2080 (W107, W123 - 65 bar)
          <br />
          # 116 460 2180 (W116.03 -82 bar)
          <br />
          #116 460 2180 (W116.036 1st version, W116.020/ .012/ .024/ .025/ .028/
          .029/ <br />
          .032/.033 - 82 bar)
          <br />
          #116 460 3080 (W116.036, 2nd version -90 bar)
        </p>
        <p>Power Steering Pump ZF</p>
        <p>
          #116 460 1780 (W123 -65 bar)) <br />
          #116 460 1880 (W 116.036, W116.020/ .012/ .024/ .025/ .028/ .029/
          .032/.033 - 82 bar) <br />
          #116 460 3080 ( W116,036, 2nd version - 90 bar
        </p>
        <h3>
          Citroen/Maserati 7 Cylinder Hydraulic Pump for Suspension and
          accessories
        </h3>
        <p>
          Hydraulic Pump for Citroen DS/SM and Maserati Merak Citroen 9S5409647L
        </p>
        <h3>Related Images</h3>
        <center>
          <b>
            <u>Scroll Through Images</u>
          </b>
        </center>
        <FuelPumpimages />
        <hr />
        <h3>Prices</h3>
        <table className="highlight bordered">
          <tbody>
            <tr>
              <td>Early Style Fuel Pump for mechanical injection, Long Pump</td>
              <td>$909</td>
            </tr>
            <tr>
              <td>Late Style Fuel Pump for mechanical injection, Short Pump</td>
              <td>$874</td>
            </tr>
            <tr>
              <td>
                Early Fuel pump for D-Jet electronic injection, early 3-Port
                design
              </td>
              <td>$874</td>
            </tr>
            <tr>
              <td>
                Late Fuel pump for D-Jet electronic injection, late 2–Port
                design
              </td>
              <td>$874</td>
            </tr>
            <tr>
              <td>PS Pump Vickers VT27</td>
              <td>$552</td>
            </tr>
            <tr>
              <td>PS Pump Vickers VT49</td>
              <td>$552</td>
            </tr>
            <tr>
              <td>PS Pump ZF (W116)</td>
              <td>$552</td>
            </tr>
            <tr>
              <td>Cold Start Valve, without magnet</td>
              <td>$449</td>
            </tr>
            <tr>
              <td>Citroen SM, Maserati Merak Pump</td>
              <td>$978</td>
            </tr>
          </tbody>
        </table>
        <h3>Contact</h3>
        <p>Send me an email or fill out the contact form to get in touch</p>
        <div className=" contactinfo">
          <div className="row">
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                {/* <div className="contactinfo"> */}
                <p className="notp">
                  Martin Werminghausen
                  <br />
                  Engineer
                  <br />
                  Professional Experience: Maybach/MTU/Mercedes in Germany
                  <br />
                  Registered Architect
                  <br />
                  Location:
                  <br />
                  141 South Great Rd.
                  <br />
                  Lincoln, MA 01773, USA
                  <br />
                  <br />
                  Email:{" "}
                  <a href="mailto:600AirSuspension@gmail.com">
                    600AirSuspension@gmail.com
                  </a>
                  {/* <div className="buttons">
                    <Button
                      style={{
                        borderRadius: 2.5,
                        fontSize: "14px",
                        float: "center",
                        marginTop: "2.5%",
                      }}
                      className="contactbut btn blue"
                      variant="contained"
                      href="mailto:600AirSuspension@gmail.com"
                    >
                      Contact
                    </Button>
                  </div> */}
                  <br />
                </p>
              </div>
            </div>
            <div className="col s12 m6">
              <div className="card-panel grey lighten-5 contactcard">
                <center>
                  <div className="submitted"></div>
                </center>
                <div className="hidemail">
                  <Emailform />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- <button class="contact"><a href="mailto:600AirSuspension@gmail.com">Contact</a></button> --> */}
      </div>
    </div>
  );
}

export default Fuelpumpmore;
