import React, { Component } from "react";
import M from "materialize-css";

import FuelPump0 from "../../public/FuelPump/FuelPump.jpg";
import FuelPump1 from "../../public/FuelPump/FuelPump1.jpg";
import FuelPump2 from "../../public/FuelPump/FuelPump2.jpg";
import FuelPump3 from "../../public/FuelPump/FuelPump3.jpg";
import FuelPump4 from "../../public/FuelPump/FuelPump4.jpg";
import FuelPump5 from "../../public/FuelPump/FuelPump5.jpg";
import FuelPump6 from "../../public/FuelPump/FuelPump6.jpg";
import FuelPump7 from "../../public/FuelPump/FuelPump7.jpg";
import FuelPump8 from "../../public/FuelPump/FuelPump8.jpg";
import FuelPump9 from "../../public/FuelPump/FuelPump9.jpg";
import FuelPump10 from "../../public/FuelPump/FuelPump10.jpg";
import FuelPump11 from "../../public/FuelPump/FuelPump11.jpg";
import FuelPump12 from "../../public/FuelPump/FuelPump12.jpg";
import FuelPump13 from "../../public/FuelPump/FuelPump13.jpg";
import FuelPump14 from "../../public/FuelPump/FuelPump14.jpg";
import FuelPump15 from "../../public/FuelPump/FuelPump15.jpg";
import FuelPump16 from "../../public/FuelPump/FuelPump16.jpg";
import FuelPump17 from "../../public/FuelPump/FuelPump17.jpg";
import FuelPump18 from "../../public/FuelPump/FuelPump18.jpg";
import FuelPump19 from "../../public/FuelPump/FuelPump19.jpg";
import FuelPump20 from "../../public/FuelPump/FuelPump20.jpg";
import FuelPump21 from "../../public/FuelPump/FuelPump21.jpg";
import FuelPump22 from "../../public/FuelPump/FuelPump22.jpg";
import FuelPump23 from "../../public/FuelPump/FuelPump23.jpg";
import FuelPump24 from "../../public/FuelPump/FuelPump24.jpg";
import FuelPump25 from "../../public/FuelPump/FuelPump25.jpg";
import FuelPump26 from "../../public/FuelPump/FuelPump26.jpg";
import FuelPump27 from "../../public/FuelPump/FuelPump27.jpg";
import FuelPump28 from "../../public/FuelPump/FuelPump28.jpg";

class FuelPumpimages extends Component {
  componentDidMount() {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems);
  }
  render() {
    return (
      <div class="carousel">
        <a class="carousel-item" href="#0">
          <img src={FuelPump0} alt="a" />
        </a>
        <a class="carousel-item" href="#1">
          <img src={FuelPump1} alt="a" />
        </a>
        <a class="carousel-item" href="2">
          <img src={FuelPump2} alt="a" />
        </a>
        <a class="carousel-item" href="3">
          <img src={FuelPump3} alt="a" />
        </a>
        <a class="carousel-item" href="4">
          <img src={FuelPump4} alt="a" />
        </a>
        <a class="carousel-item" href="#5">
          <img src={FuelPump5} alt="a" />
        </a>
        <a class="carousel-item" href="#6">
          <img src={FuelPump6} alt="a" />
        </a>
        <a class="carousel-item" href="#7">
          <img src={FuelPump7} alt="a" />
        </a>
        <a class="carousel-item" href="#8">
          <img src={FuelPump8} alt="a" />
        </a>
        <a class="carousel-item" href="#9">
          <img src={FuelPump9} alt="a" />
        </a>
        <a class="carousel-item" href="#10">
          <img src={FuelPump10} alt="a" />
        </a>
        <a class="carousel-item" href="#11">
          <img src={FuelPump11} alt="a" />
        </a>
        <a class="carousel-item" href="#12">
          <img src={FuelPump12} alt="a" />
        </a>
        <a class="carousel-item" href="#13">
          <img src={FuelPump13} alt="a" />
        </a>
        <a class="carousel-item" href="#14">
          <img src={FuelPump14} alt="a" />
        </a>
        <a class="carousel-item" href="#15">
          <img src={FuelPump15} alt="a" />
        </a>
        <a class="carousel-item" href="#16">
          <img src={FuelPump16} alt="a" />
        </a>
        <a class="carousel-item" href="#17">
          <img src={FuelPump17} alt="a" />
        </a>
        <a class="carousel-item" href="#18">
          <img src={FuelPump18} alt="a" />
        </a>
        <a class="carousel-item" href="#19">
          <img src={FuelPump19} alt="a" />
        </a>
        <a class="carousel-item" href="#20">
          <img src={FuelPump20} alt="a" />
        </a>
        <a class="carousel-item" href="#21">
          <img src={FuelPump21} alt="a" />
        </a>
        <a class="carousel-item" href="#22">
          <img src={FuelPump22} alt="a" />
        </a>
        <a class="carousel-item" href="#23">
          <img src={FuelPump23} alt="a" />
        </a>
        <a class="carousel-item" href="#24">
          <img src={FuelPump24} alt="a" />
        </a>
        <a class="carousel-item" href="#25">
          <img src={FuelPump25} alt="a" />
        </a>
        <a class="carousel-item" href="#26">
          <img src={FuelPump26} alt="a" />
        </a>
        <a class="carousel-item" href="#27">
          <img src={FuelPump27} alt="a" />
        </a>
        <a class="carousel-item" href="#28">
          <img src={FuelPump28} alt="a" />
        </a>
      </div>
    );
  }
}

export default FuelPumpimages;
