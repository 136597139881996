import { Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./pages/ScrollToTop";

// import Home from "./pages/Home/Home";
import Airsuspension from "./pages/AirSuspension/AirSuspension";
import Comhyd from "./pages/600Comhyd/600Comhyd";
import Hpf from "./pages/6.9hpf/6.9hpf";
import Fuelpumpmore from "./pages/FuelPump&More/FuelPump&More";

import "./App.css";
import "./pages/Home/home.css";
import "./pages/AirSuspension/airsuspension.css";

function App() {
  return (
    <div className="App">
      <Nav />
      <ScrollToTop />
      <Switch>
        {/* <Route exact path="/">
          <Home />
        </Route> */}
        <Route exact path="/">
          <Airsuspension />
        </Route>
        <Route exact path="/comforthydraulics">
          <Comhyd />
        </Route>
        <Route exact path="/6.9hpf">
          <Hpf />
        </Route>
        <Route exact path="/fuel-pumps-and-more">
          <Fuelpumpmore />
        </Route>
        {/* <Route exact path="/games/breakout">
          <Breakout />
        </Route>
        <Route exact path="/canvas">
          <Canvas />
        </Route> */}
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
