// import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import { NavLink } from "react-router-dom";

import "../index.css";

const Nav = () => {
  // const currentRoute = useHistory().location.pathname.toLowerCase();
  return (
    <>
      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper grey darken-3 ">
            {/* <Button
            style={{
              borderRadius: 5,
              backgroundColor: "#ffa722",
              padding: "10px 0px",
              fontSize: "12px",
              float: "center",
            }}
            className="ButtonAir2"
            variant="contained"
            size="large"
            href="https://600airsuspensionshop.com/collections/air-suspension"
          >
            600 AIR SUSPENSION SHOP
            <i className="material-icons">shopping_cart</i>
          </Button> */}
            <a href="/" data-target="mobile-demo" className="sidenav-trigger">
              <i className="material-icons">menu </i>
            </a>
            <ul className="left hide-on-med-and-down">
              <li>
                <NavLink
                  activeClassName="active"
                  className={"tab"}
                  exact
                  to="/"
                >
                  Air Suspension
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className={"tab"}
                  to="/comforthydraulics"
                >
                  600 Comfort Hydraulics
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className={"tab"}
                  to="/6.9hpf"
                >
                  6.9 HPF
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className={"tab"}
                  to="/fuel-pumps-and-more"
                >
                  Fuel Pumps, PS Pumps and More
                </NavLink>
              </li>
            </ul>
            <ul className="right hide-on-med-and-down">
              <li>
                <a
                  href="https://600airsuspensionshop.com/collections/air-suspension"
                  className=" right"
                  target="_blank"
                  activeClassName="active"
                  rel="noreferrer"
                >
                  Shop Parts
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <ul className="sidenav sidenav-close navbar-fixed" id="mobile-demo">
        <li>
          <NavLink activeClassName="active" className={"tab"} exact to="/">
            Air Suspension
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            className={"tab"}
            to="/comforthydraulics"
          >
            600 Comfort Hydraulics
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" className={"tab"} to="/6.9hpf">
            6.9 HPF
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            className={"tab"}
            to="/fuel-pumps-and-more"
          >
            Fuel Pumps, PS Pumps and More
          </NavLink>
        </li>
        <hr />
        <li>
          <a
            href="https://600airsuspensionshop.com/collections/air-suspension"
            // className=" right"
            className={"tab"}
            target="_blank"
            rel="noreferrer"
          >
            Shop Parts
          </a>
        </li>
      </ul>
    </>
  );
};

export default Nav;
