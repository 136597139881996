import React, { Component } from "react";
import M from "materialize-css";

import HPF1 from "../../public/HPF/HPF1.jpg";
import HPF2 from "../../public/HPF/HPF2.jpg";
import HPF3 from "../../public/HPF/HPF3.jpg";
import HPF4 from "../../public/HPF/HPF4.jpg";
import HPF5 from "../../public/HPF/HPF5.jpg";
import HPF6 from "../../public/HPF/HPF6.jpg";
import HPF7 from "../../public/HPF/HPF7.jpg";
import HPF8 from "../../public/HPF/HPF8.jpg";
import HPF9 from "../../public/HPF/HPF9.jpg";
import HPF10 from "../../public/HPF/HPF10.jpg";
import HPF11 from "../../public/HPF/HPF11.jpg";
import HPF12 from "../../public/HPF/HPF12.jpg";
import HPF13 from "../../public/HPF/HPF13.jpg";
import HPF14 from "../../public/HPF/HPF14.jpg";
import HPF15 from "../../public/HPF/HPF15.jpg";
import HPF16 from "../../public/HPF/HPF16.jpg";
import HPF17 from "../../public/HPF/HPF17.jpg";
import HPF18 from "../../public/HPF/HPF18.jpg";
import HPF19 from "../../public/HPF/HPF19.jpg";
import HPF20 from "../../public/HPF/HPF20.jpg";
// import HPF21 from "../../public/HPF/HPF21.jpg";

class HPFimages extends Component {
  componentDidMount() {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems);
  }
  render() {
    return (
      <div class="carousel">
        <a class="carousel-item" href="#1">
          <img src={HPF1} alt="a" />
        </a>
        <a class="carousel-item" href="#2">
          <img src={HPF2} alt="a" />
        </a>
        <a class="carousel-item" href="#3">
          <img src={HPF3} alt="a" />
        </a>
        <a class="carousel-item" href="#4">
          <img src={HPF4} alt="a" />
        </a>
        <a class="carousel-item" href="#5">
          <img src={HPF5} alt="a" />
        </a>
        <a class="carousel-item" href="#5">
          <img src={HPF6} alt="a" />
        </a>
        <a class="carousel-item" href="#6">
          <img src={HPF7} alt="a" />
        </a>
        <a class="carousel-item" href="#7">
          <img src={HPF8} alt="a" />
        </a>
        <a class="carousel-item" href="#8">
          <img src={HPF9} alt="a" />
        </a>
        <a class="carousel-item" href="#9">
          <img src={HPF10} alt="a" />
        </a>
        <a class="carousel-item" href="#10">
          <img src={HPF11} alt="a" />
        </a>
        <a class="carousel-item" href="#11">
          <img src={HPF12} alt="a" />
        </a>
        <a class="carousel-item" href="#12">
          <img src={HPF13} alt="a" />
        </a>
        <a class="carousel-item" href="#13">
          <img src={HPF14} alt="a" />
        </a>
        <a class="carousel-item" href="#15">
          <img src={HPF15} alt="a" />
        </a>
        <a class="carousel-item" href="#16">
          <img src={HPF16} alt="a" />
        </a>
        <a class="carousel-item" href="#17">
          <img src={HPF17} alt="a" />
        </a>
        <a class="carousel-item" href="#18">
          <img src={HPF18} alt="a" />
        </a>
        <a class="carousel-item" href="#19">
          <img src={HPF19} alt="a" />
        </a>
        <a class="carousel-item" href="#20">
          <img src={HPF20} alt="a" />
        </a>
        {/* <a class="carousel-item" href="#21">
          <img src={HPF21} alt="a" />
        </a> */}
      </div>
    );
  }
}

export default HPFimages;
