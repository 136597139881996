import React, { Component } from "react";
import M from "materialize-css";

import KH1 from "../../public/KH/KH_image_slide_01.jpg";
import KH2 from "../../public/KH/KH_image_slide_02.jpg";
import KH3 from "../../public/KH/KH_image_slide_03.jpg";
import KH4 from "../../public/KH/KH_image_slide_04.jpg";
import KH5 from "../../public/KH/KH_image_slide_05.jpg";
import KH6 from "../../public/KH/KH_image_slide_06.jpg";
import KH7 from "../../public/KH/KH_image_slide_14.jpg";
import KH8 from "../../public/KH/KH_image_slide_15.jpg";
import KH9 from "../../public/KH/KH_image_slide_16.jpg";
import KH10 from "../../public/KH/KH_image_slide_17.jpg";
import KH11 from "../../public/KH/KH_image_slide_18.jpg";
import KH12 from "../../public/KH/KH_image_slide_19.jpg";
import KH13 from "../../public/KH/KH_image_slide_20.jpg";
import KH14 from "../../public/KH/KH_image_slide_21.jpg";
import KH15 from "../../public/KH/KH_image_slide_22.jpg";
import KH16 from "../../public/KH/KH_image_slide_23.jpg";
import KH17 from "../../public/KH/KH_image_slide_24.jpg";
import KH18 from "../../public/KH/KH_image_slide_25.jpg";
import KH19 from "../../public/KH/KH_image_slide_26.jpg";
import KH20 from "../../public/KH/KH_image_slide_27.jpg";
import KH21 from "../../public/KH/KH_image_slide_28.jpg";
import KH22 from "../../public/KH/KH_image_slide_29.jpg";
import KH23 from "../../public/KH/KH_image_slide_30.jpg";
import KH24 from "../../public/KH/KH_image_slide_31.jpg";

import KH25 from "../../public/KH/KH1.jpg";
import KH26 from "../../public/KH/KH2.jpg";
import KH27 from "../../public/KH/KH3.jpg";
import KH28 from "../../public/KH/KH4.jpg";
import KH29 from "../../public/KH/KH5.jpg";
import KH31 from "../../public/KH/KH7.jpg";
import KH32 from "../../public/KH/KH8.jpg";
import KH33 from "../../public/KH/KH9.jpg";
import KH34 from "../../public/KH/KH10.jpg";

class KHimages extends Component {
  componentDidMount() {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems);
  }
  render() {
    return (
      <div class="carousel">
        <a class="carousel-item" href="#1">
          <img src={KH1} alt="a" />
        </a>
        <a class="carousel-item" href="2">
          <img src={KH2} alt="a" />
        </a>
        <a class="carousel-item" href="3">
          <img src={KH3} alt="a" />
        </a>
        <a class="carousel-item" href="4">
          <img src={KH4} alt="a" />
        </a>
        <a class="carousel-item" href="#5">
          <img src={KH5} alt="a" />
        </a>
        <a class="carousel-item" href="#6">
          <img src={KH6} alt="a" />
        </a>
        <a class="carousel-item" href="#7">
          <img src={KH7} alt="a" />
        </a>
        <a class="carousel-item" href="#8">
          <img src={KH8} alt="a" />
        </a>
        <a class="carousel-item" href="#9">
          <img src={KH9} alt="a" />
        </a>
        <a class="carousel-item" href="#10">
          <img src={KH10} alt="a" />
        </a>
        <a class="carousel-item" href="#11">
          <img src={KH11} alt="a" />
        </a>
        <a class="carousel-item" href="#12">
          <img src={KH12} alt="a" />
        </a>
        <a class="carousel-item" href="#13">
          <img src={KH13} alt="a" />
        </a>
        <a class="carousel-item" href="#14">
          <img src={KH14} alt="a" />
        </a>
        <a class="carousel-item" href="#15">
          <img src={KH15} alt="a" />
        </a>
        <a class="carousel-item" href="#16">
          <img src={KH16} alt="a" />
        </a>
        <a class="carousel-item" href="#17">
          <img src={KH17} alt="a" />
        </a>
        <a class="carousel-item" href="#18">
          <img src={KH18} alt="a" />
        </a>
        <a class="carousel-item" href="#19">
          <img src={KH19} alt="a" />
        </a>
        <a class="carousel-item" href="#20">
          <img src={KH20} alt="a" />
        </a>
        <a class="carousel-item" href="#21">
          <img src={KH21} alt="a" />
        </a>
        <a class="carousel-item" href="#22">
          <img src={KH22} alt="a" />
        </a>
        <a class="carousel-item" href="#23">
          <img src={KH23} alt="a" />
        </a>
        <a class="carousel-item" href="#24">
          <img src={KH24} alt="a" />
        </a>
        <a class="carousel-item" href="#25">
          <img src={KH25} alt="a" />
        </a>
        <a class="carousel-item" href="#26">
          <img src={KH26} alt="a" />
        </a>
        <a class="carousel-item" href="#27">
          <img src={KH27} alt="a" />
        </a>
        <a class="carousel-item" href="#28">
          <img src={KH28} alt="a" />
        </a>
        <a class="carousel-item" href="#29">
          <img src={KH29} alt="a" />
        </a>
        <a class="carousel-item" href="#31">
          <img src={KH31} alt="a" />
        </a>
        <a class="carousel-item" href="#32">
          <img src={KH32} alt="a" />
        </a>
        <a class="carousel-item" href="#33">
          <img src={KH33} alt="a" />
        </a>
        <a class="carousel-item" href="#34">
          <img src={KH34} alt="a" />
        </a>
      </div>
    );
  }
}

export default KHimages;
