import React, { useEffect } from "react";
import M from "materialize-css";
// import AS31 from "../../public/AS_image_slide_31.jpg";

import AS1 from "../../public/AS/AS_image_slide_03.jpg";
import AS2 from "../../public/AS/AS_image_slide_04.jpg";
import AS3 from "../../public/AS/AS_image_slide_05.jpg";
import AS4 from "../../public/AS/AS_image_slide_07.jpg";
import AS5 from "../../public/AS/AS_image_slide_13.jpg";
import AS6 from "../../public/AS/AS_image_slide_16.jpg";
import AS7 from "../../public/AS/AS_image_slide_17.jpg";
import AS8 from "../../public/AS/AS_image_slide_18.jpg";
import AS9 from "../../public/AS/AS_image_slide_27.jpg";
import AS11 from "../../public/AS/AS_image_slide_31.jpg";
import AS12 from "../../public/AS/AS_image_slide_32.jpg";
import AS13 from "../../public/AS/AS_image_slide_43.jpg";
import AS14 from "../../public/AS/AS_image_slide_47.jpg";
import AS15 from "../../public/AS/AS_image_slide_53.jpg";
import AS16 from "../../public/AS/AS_image_slide_61.jpg";
import AS18 from "../../public/AS/AS_image_slide_63.jpg";
import AS19 from "../../public/AS/AS_image_slide_64.jpg";

import AS20 from "../../public/AS/AS1.jpg";
import AS21 from "../../public/AS/AS2.jpg";
import AS22 from "../../public/AS/AS3.jpg";
import AS23 from "../../public/AS/AS4.jpg";
import AS24 from "../../public/AS/AS5.jpg";
import AS25 from "../../public/AS/AS6.jpg";
import AS26 from "../../public/AS/AS7.jpg";
import AS27 from "../../public/AS/AS8.jpg";
import AS28 from "../../public/AS/AS9.jpg";
import AS29 from "../../public/AS/AS10.jpg";
import AS30 from "../../public/AS/AS11.jpg";
// import AS31 from "../../public/AS/AS12.jpg";
// import AS32 from "../../public/AS/AS13.jpg";
import AS33 from "../../public/AS/AS14.jpg";
import AS34 from "../../public/AS/AS15.jpg";

function ASImages(props) {
  // componentDidMount() {
  //   var elems = document.querySelectorAll(".carousel");
  //   M.Carousel.init(elems);
  // }
  // render() {
  useEffect(() => {
    console.log("I have been mounted");
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems);
    console.log("I have been mounted2");
  }, []);
  return (
    <div class="carousel">
      <a class="carousel-item" href="#1">
        <img src={AS1} alt="a" />
      </a>
      <a class="carousel-item" href="2">
        <img src={AS2} alt="a" />
      </a>
      <a class="carousel-item" href="3">
        <img src={AS3} alt="a" />
      </a>
      <a class="carousel-item" href="4">
        <img src={AS4} alt="a" />
      </a>
      <a class="carousel-item" href="#5">
        <img src={AS5} alt="a" />
      </a>
      <a class="carousel-item" href="#6">
        <img src={AS6} alt="a" />
      </a>
      <a class="carousel-item" href="#7">
        <img src={AS7} alt="a" />
      </a>
      <a class="carousel-item" href="#8">
        <img src={AS8} alt="a" />
      </a>
      <a class="carousel-item" href="#9">
        <img src={AS9} alt="a" />
      </a>
      <a class="carousel-item" href="#11">
        <img src={AS11} alt="a" />
      </a>
      <a class="carousel-item" href="#12">
        <img src={AS12} alt="a" />
      </a>
      <a class="carousel-item" href="#13">
        <img src={AS13} alt="a" />
      </a>
      <a class="carousel-item" href="#14">
        <img src={AS14} alt="a" />
      </a>
      <a class="carousel-item" href="#15">
        <img src={AS15} alt="a" />
      </a>
      <a class="carousel-item" href="#16">
        <img src={AS16} alt="a" />
      </a>
      <a class="carousel-item" href="#18">
        <img src={AS18} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS19} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS20} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS21} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS22} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS23} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS24} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS25} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS26} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS27} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS28} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS29} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS30} alt="a" />
      </a>
      {/* <a class="carousel-item" href="#19">
          <img src={AS31} alt="a" />
        </a> */}
      {/* <a class="carousel-item" href="#19">
          <img src={AS32} alt="a" />
        </a> */}
      <a class="carousel-item" href="#19">
        <img src={AS33} alt="a" />
      </a>
      <a class="carousel-item" href="#19">
        <img src={AS34} alt="a" />
      </a>
    </div>
  );
}

export default ASImages;
