import Button from "@mui/material/Button";
import Banner from "react-js-banner";
import airsuspensionlogo from "../../public/600-airsuspension.jpg";

import ASimages from "../AirSuspension/AS-images.js";
import Emailform from "./Emailform";
// import Images from "./imagetest";

function Airsuspension(props) {
  return (
    <div className="home">
      <Banner
        title="New Pricing Valid January 2024"
        visibleTime={100000}
        css={{
          color: "#FFF",
          backgroundColor: "orange",
          opacity: 0.7,
          fontSize: 18,
          margin: 0,
          height: 25,
          padding: 25,
          // borderRadius: 10,
        }}
      />

      <div id="wrap">
        <div class="content-tab air-suspension active">
          <div className="getFlexed">
            <img
              className="logo"
              src={airsuspensionlogo}
              alt="logo"
              title="logo"
              style={{ maxHeight: "300px !important" }}
            />
          </div>
          <b className="boldbig">For replacement parts, please visit:</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            style={{
              borderRadius: 5,
              backgroundColor: "#ffa722",
              padding: "6px 6px",
              float: "center",
            }}
            className="ButtonAir"
            variant="contained"
            size="large"
            fontSize="bold"
            target="_blank"
            rel="noreferrer"
            href="https://600airsuspensionshop.com/collections/air-suspension"
          >
            600 AIR SUSPENSION SHOP
            {/* <i className="material-icons">shopping_cart</i> */}
          </Button>
          <h2>
            <b>Mercedes Air Suspension Repair Services</b>
          </h2>
          <hr />
          <h3>What we do</h3>
          <p>
            We are offering professional rebuilding for the classic Mercedes Air
            Suspension from 1963 to the early 70s (Mercedes 600 from 1963
            to1981), mainly level control valves, valve unit, anti-freeze
            device, air tank, air springs, compressor and pressure regulator for
            the 600 , W112 and W109. We also do the 600-brake booster and we can
            rebuild air springs and build air lines (steel and flex lines).
          </p>
          <h3>Legendary Air Suspension</h3>
          <p>
            You are probably familiar with the Mercedes models W 109, W 112 or
            even the W 100 from this time.
            <br />
            <br />
            These models were designed with a ‘legendary‘ air suspension system,
            legendary for the superior ride but also legendary for the
            complications the owner might experience with the old system
            starting to fail.
            <br />
            <br />
            The air suspension system technically was probably the best
            suspension system on the market at the time it was designed. It was
            for sure the best-engineered system. However even the best system
            needs rebuilding.
            <br />
            <br />
            Oh yes, you will notice when the time has come. If your car drops
            within a week, you need to get ready.
          </p>
          <h3>Maintenance and Repair</h3>
          <p>
            No doubt, the air suspension requires a high degree of maintenance.
            And most possibly your system will work perfectly fine if it was
            brought back to spec. This means the failing components need to be
            repaired/ rebuilt and the system maintained according Mercedes
            instructions.
            <br />
            <br />
            The bad news: The repair can be overwhelming and rebuilding the
            units by a qualified specialist does not come cheap. Engaged and
            experienced DIY people can repair some of the air suspension
            components but the renovation of the core components like the 3
            level control valves, main valve, anti-freeze device and pressure
            regulator should be in the hands of the specialist. Too much
            knowledge, experience, specialized tools and precise instruments,
            testing equipment and parts are needed for rebuilding.
            <br />
            <br />
            From my personal experience I can tell that you will enjoy every bit
            of a ride with a proper working Mercedes air suspension.
          </p>
          <h3>Our Work</h3>
          <p>
            We work with passion and our products are based on integrity,
            honesty and service. We share our knowledge with the community and
            we like collaborating. We use the best technology and craftsmanship
            available. Our CNC parts are ‘Made in Germany’ and we use high
            quality seal rings and materials. All repaired units and parts and
            units run through testing. We are giving a 2-year warranty for air
            tightness and function within specification for units and parts. You
            are requested to send your units for rebuilding before we start and
            we will rebuild the units normally within ca. 2-4 weeks of
            turn-around or upon request. Prices are based on the assumption that
            provided cores are repairable which typically is the case. We will
            keep our clients informed about the process and communicate if there
            is a problem.
            <br />
            <br />
            We take a $250 core charge for LCV-VNC, Anti- Freeze Device and
            Valve Unit and VNC , $600 for the Pressure Regulator in case we
            deliver up front.
          </p>
          <h3>What is done during Renovation?</h3>
          <p>
            <div class="bold">Level control valve renovation: </div>{" "}
            Disassembly, all parts cleaned, threads and bores recut/reamed, unit
            body cleaned (we don’t do harsh blasting), steel parts yellow zinc
            plated/ chromate, seals exchanged, inner valves replaced by new
            parts, proper lubrication, adjustments according MB specification,
            pressure testing, new custom seals for air line fittings. VNB units
            receive new rubber/steel bearings.
            <br />
            <br />
            <div class="bold">Valve unit renovation: </div>Disassembly, all
            parts cleaned, threads and bores recut/reamed unit body cleaned (no
            harsh blasting), steel parts yellow zinc plated/ chromate, seals
            exchanged, inner valves exchanged by new ones, proper lubrication,
            adjustments according MB spec, pressure testing, new custom seals
            for air line fittings. Pressure switch will be tested but is
            excluded from repair. In case the pressure switch is defective a new
            switch can replace it.
            <br />
            <br />
            <div class="bold">Anti-freeze device renovation: </div>Disassembly,
            all parts cleaned, threads and bores recut/reamed unit body cleaned
            (no blasting), steel parts yellow zinc plated/ chromate, seals
            exchanged, inner valves exchanged by new parts, adjustments
            according MB spec., pressure testing, exchange of paper air filter
            element.
            <br />
            <br />
            <div class="bold">Pressure regulator (600 only) renovation: </div>
            Disassembly, all parts cleaned, threads and bores recut/reamed unit
            body cleaned (no harsh blasting), steel parts yellow zinc plated/
            chromate, seals exchanged, inner valves exchanged by new parts,
            adjustments according MB spec., pressure testing, lubrication, new
            seals.
            <br />
            <br />
            <div class="bold">
              Drain Valve and Check Valve (air tank) rebuilding:
            </div>
            Disassembly, all parts cleaned, steel parts yellow zinc plated or
            stainless steel brushed, inner valve exchanged by new part.
            <br />
            <br />
            <div class="bold">600 Air Brake Booster: </div>
            Full rebuilding of the brake booster with new seals, new plate valve
            and new interior air hose. All steel parts yellow plated, fully
            pressure tested. Bosch SV/DKV1/115/44/2
            <br />
            Bosch 0 443 003 004 <br />
            Mercedes Benz A0024300301
            <br />
            <br />
            <div class="bold">Air Compressor rebuilding: </div>
            Disassembly, all parts cleaned, threads and bores recut/reamed unit
            body cleaned (no harsh blasting), steel parts yellow zinc plated/
            chromate, seals and gaskets exchanged, inner reed valves replaced by
            new parts. valve seats machined. Exchange of bronze bushings and
            piston pins if necessary (added to base price)
            <br />
            <br />
            Manipulated Units: Very rarely I am finding ‘manipulated’ units.
            These are cores that were previously "repaired" by non-
            professionals leading to partial destruction of inner parts. These
            can be very difficult and time consuming to repair due to broken or
            missing parts. These parts need to be machined and the effort is
            obvious. Please understand that working on manipulated units will
            possibly cost some extra effort and in case I will communicate and
            discuss with the client. Thanks for your understanding.
            <br />
            <br />
            Please do not hesitate and ask questions.
            <br />
            <br />
          </p>
          {/* <h3>Parts under development</h3>
          <p>
            Pressure Test Adapter: This tool is for easy testing and monitoring
            air pressure in the support group (air tank) and filling the system
            with shop air.
            <br /> This tool is for the professional Mercedes shop but also for
            Mercedes enthusiast.
            <br /> This tool screws into the pressure switch port of the valve
            unit and allows for easy and safe pressure testing / monitoring with
            a standard AC gauge and 1/4" flare hose. With a long hose system
            pressure can be monitored during driving.
          </p> */}
          <h3>Articles</h3>
          <ul>
            <li>
              <a
                href="http://600airsuspension.com/resources/150217-article-on-mercedes-air-suspension-by-martin-werminghausen.pdf"
                target="_blank"
                rel="noreferrer"
                alt="article"
                title="Article"
              >
                Basics about Mercedes Air Suspension by Martin Werminghausen
              </a>
              <div className="redd">*Updated Feb 17, 2015</div>
            </li>
            <li>
              <a
                href="resources/140408-manual-for-axle-height-adjustment.pdf"
                target="_blank"
                rel="noreferrer"
                alt="article"
                title="Article"
              >
                Installation and Height Adjustment for Level Control Valves Type
                LF/VNC for W112, W109 and W100 by Martin Werminghausen
              </a>
            </li>
            <ul>
              <li>
                <a
                  href="resources/141020_Ajuste_e_instalación_de_las_válvulas_para_el_Mercedes-Benz_W100_W109_y_W112.pdf"
                  target="_blank"
                  rel="noreferrer"
                  alt="article"
                  title="Article"
                >
                  Installation and Height Adjustment in Spanish translated by
                  Gustavo Dahlgren, Panama
                </a>
              </li>
            </ul>
            <li>
              <a
                href="http://600airsuspension.com/resources/150315-article-about-antifreeze-and-filter.pdf"
                target="_blank"
                rel="noreferrer"
                alt="article"
                title="Article"
              >
                Anti-freeze, Air Filter and Air Tank - by Martin Werminghausen
              </a>
              <div className="redd">*Updated Mar 15, 2015</div>
            </li>
            <ul>
              <li>
                <a
                  href="resources/mercedes-anti-freeze--air-filter-and-air-tank--in-spanish.pdf"
                  target="_blank"
                  rel="noreferrer"
                  alt="article"
                  title="Article"
                >
                  Mercedes Anti-freeze, Air filter and Air tank in Spanish
                  translated by Gustavo Dahlgren, Panama
                </a>
              </li>
            </ul>
            <li>
              <a
                href="http://600airsuspension.com/resources/171226-Article-Air-Compressor.pdf"
                target="_blank"
                rel="noreferrer"
                alt="article"
                title="Article"
              >
                Air Compressor by Martin Werminghausen
              </a>
              <div className="redd">*Updated Dec, 2017</div>
            </li>
            <li>
              <a
                href="http://600airsuspension.com/resources/AirSuspension-Sherlock-Tests.pdf"
                target="_blank"
                rel="noreferrer"
                alt="article"
                title="Article"
              >
                AirSuspension Sherlock Tests by Martin Werminghausen
              </a>
              <div className="redd">*Updated Oct, 2020</div>
            </li>
            <ul>
              <li>
                <a
                  href="http://600airsuspension.com/resources/AirSuspension-Sherlock-Tests-Log.pdf"
                  target="_blank"
                  rel="noreferrer"
                  alt="article"
                  title="Article"
                >
                  Log for AirSuspension Sherlock Tests by Martin Werminghausen
                </a>
              </li>
            </ul>
          </ul>
          <h3>Related Videos</h3>
          <ul>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=0aRJGa4hjfM"
                target="_blank"
                rel="noreferrer"
              >
                Video 1 : Kent Bergsma - Intro repairing leveling valves, W109
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=QkmZVu1zodY"
                target="_blank"
                rel="noreferrer"
              >
                Video 2: Kent Bergsma - Components of air suspension removed,
                W109
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=qYsiE41-uL8"
                target="_blank"
                rel="noreferrer"
              >
                Video 3: Kent Bergsam - Removing leveling valves, W109
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=mmqmpmzl9y0"
                target="_blank"
                rel="noreferrer"
              >
                Video 4: Kent Bergsma - Emergency buffers, W 109
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=jwY85kkkdgk"
                target="_blank"
                rel="noreferrer"
              >
                Video 5: Martin Werminghausen - Key parts of the air suspension,
                part 1
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=UONWlDJ75ZM"
                target="_blank"
                rel="noreferrer"
              >
                Video 6: Martin Werminghausen - Key parts of the air suspension,
                part 2
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=HNnfvnhld-g"
                target="_blank"
                rel="noreferrer"
              >
                Video 7: Kent Bergsma - Installation of leveling valves, W109
              </a>
            </li>
            <li>
              {" "}
              <a
                href="http://www.youtube.com/watch?v=ryb3DrVd-X8"
                target="_blank"
                rel="noreferrer"
              >
                Video 8: Martin Werminghausen- Nullzone adjustment of the VNC
                leveling valve
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/watch?v=56wB_phzBjI"
                target="_blank"
                rel="noreferrer"
              >
                Video 9: Kent Bergsma - air suspension inspection
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/watch?v=2PHz_btr560"
                target="_blank"
                rel="noreferrer"
              >
                Video 10: Kent Bergsma - rebuilt leveling valves and valve unit
                from 600 air suspension
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/watch?v=upTAGcVc-CQ"
                target="_blank"
                rel="noreferrer"
              >
                Video 11: Kent Bergsma - new air springs{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/watch?v=W5mVcXNfRQQ"
                target="_blank"
                rel="noreferrer"
              >
                Video 12: Kent Bergsma - air suspension anti freeze unit and
                tank
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/watch?v=z6KmqaLoXbg"
                target="_blank"
                rel="noreferrer"
              >
                Video 13: Kent Bergsma - initial ride height adjustment.
              </a>
            </li>
          </ul>
          <h3>Related Images</h3>
          <center>
            <b>
              <u>Scroll Through Images</u>
            </b>
          </center>
          <ASimages />
          <hr />
          <h2>Mercedes Air Suspension Repair Services</h2>
          <h3>Pricing</h3>
          <p>
            Prices for rebuilding Mercedes Air Suspension W109 1965-72, W112
            8/65-67, W100 1963-84 and W112-8/65
            <br />
            Units to be sent for rebuilding
          </p>
          <table className="highlight bordered">
            <tbody>
              <tr>
                <th>
                  Level Control Valve for W112, W109 and W100, complete
                  rebuilding.
                  <br />
                  LF/VNC 3/1 [0-500-003-004],
                  <br />
                  LF/VNC 3/2 [0-500-003-005],
                  <br />
                  LF/VNC 4/3 [0-500-003-006].
                  <br />
                  These are the 3 later style units for front- right, front-
                  left and rear.
                </th>
                <td>$728 per unit</td>
              </tr>
              <tr>
                <th>
                  Level Control Valve for W112 , complete rebuilding.
                  <br />
                  LF/VNB 3B2 / 3A2, Front Left
                  <br />
                  LF/VNB 1B1/ 1A1, Front Right
                  <br />
                  LF/VNB 2B1 / 2A1, Rear
                  <br />
                  These are the 3 earlier style units, before 1965
                </th>
                <td>
                  {/* <s>$968</s> */}
                  $978 per unit
                </td>
              </tr>
              <tr>
                <th>
                  Main Valve for W112, W109 and W100, complete rebuilding,
                  pressure switch excluded.
                  <br />
                  0-501-001-004,
                  <br />
                  0-501-001-005
                </th>
                <td>$695 per unit</td>
              </tr>
              <tr>
                <th>
                  Anti-freeze device, rebuilding inner valves and all rubber
                  seals, paper air filter change included.
                  <br />
                  000-431-14-15
                </th>
                <td>$615 per unit</td>
              </tr>
              <tr>
                <th>
                  600 Pressure Regulator (W100), complete rebuilding and
                  pressure adjustments.
                  <br />
                  0-481-040-001,
                  <br />
                  0-481-040-002
                </th>
                <td>$1,290 per unit</td>
              </tr>
              <tr>
                <th>
                  Drain Valve (bottom of air tank), complete renovation
                  <br />
                  LF/ VE [0-500-400-001, -002]
                </th>
                <td>$175 per unit</td>
              </tr>
              <tr>
                <th>
                  Main Check Valve (engine side of air tank, complete renovation
                  <br />
                  LF/VB (0- 500- 100- 001, -002, -003)
                </th>
                <td>$150 per unit</td>
              </tr>
              <tr>
                <th>
                  Air Filter Unit (part of anti freeze device) complete
                  <br />
                  renovation, paper air filter change included
                </th>
                <td>$165 per unit</td>
              </tr>
              <tr>
                <th>
                  Air Compressor
                  <br />
                  additional parts/machining bronze bearing
                  <br />
                  new piston pin{" "}
                </th>
                <td>
                  $1,130 per unit
                  <br />
                  $150
                  <br />
                  $35
                </td>
              </tr>
              <tr>
                <th>LF 600 Horn Valve</th>
                <td>$650</td>
              </tr>
              <tr>
                <th>LF Flex Air Hoses</th>
                <td>$149</td>
              </tr>
              <tr>
                <th>
                  600 Air Brake Booster <br />
                  Bosch SV/DKV1/115/44/2 , 0 443 003 004 <br />
                  Mercedes Benz A0024300301
                </th>
                <td>$2,950</td>
              </tr>
            </tbody>
          </table>
          <p>
            Customer is covering shipping, duties and customs clearing (for
            shipping outside the US)
            <br />
            <br />
            Core charges for up front services:
            <br />
            $300 for LCV-VNC and Anti-Freeze Device core
            <br />
            $300 for the Valve Unit core
            <br />
            $600 for the Pressure Regulator core
            <br />
            Core charge will be refunded after receipt of core
          </p>
          <h3>Contact</h3>
          <p>Send me an email or fill out the contact form to get in touch</p>
          {/* <div className="flexcontact"> */}
          <div className=" contactinfo">
            <div className="row">
              <div className="col s12 m6">
                <div className="card-panel grey lighten-5 contactcard">
                  {/* <div className="contactinfo"> */}
                  <p className="notp">
                    Martin Werminghausen
                    <br />
                    Engineer
                    <br />
                    Professional Experience: Maybach/MTU/Mercedes in Germany
                    <br />
                    Registered Architect
                    <br />
                    Location:
                    <br />
                    141 South Great Rd.
                    <br />
                    Lincoln, MA 01773, USA
                    <br />
                    <br />
                    Email:{" "}
                    <a href="mailto:600AirSuspension@gmail.com">
                      600AirSuspension@gmail.com
                    </a>
                    {/* <div className="buttons">
                      <Button
                        style={{
                          borderRadius: 2.5,
                          fontSize: "14px",
                          float: "center",
                          marginTop: "2.5%",
                        }}
                        className="contactbut btn blue"
                        variant="contained"
                        href="mailto:600AirSuspension@gmail.com"
                      >
                        Contact
                      </Button>
                    </div> */}
                    <br />
                  </p>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="card-panel grey lighten-5 contactcard">
                  <center>
                    <div className="submitted"></div>
                    {/* <div className="emailicon">
                    <i className="material-icons">mail_outline</i>
                    </div> */}
                  </center>
                  <div className="hidemail">
                    <Emailform />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- <button class="contact"><a href="mailto:600AirSuspension@gmail.com">Contact</a></button> --> */}
          <br />
          <h3>Reviews</h3>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    David Southwell
                  </h5>
                  Martin- I bought my 300 SEL 6.3 in late 2018- after watching
                  Jay Leno's review- and got it sorted enough to drive the
                  following summer through John Robison's excellent shop.
                  However, it was losing air after only a day or so. The
                  following winter I considered replacing the air suspension
                  with springs, but decided to do it right. After your work
                  rebuilding the air valves, it's as though I have a new car. I
                  just had to spend a month away, and was delighted to see my
                  Mercedes high and level when I returned. When I fired her up
                  there was no extra air at all needed from the compressor.
                  You're an extraordinary resource for these cars- thanks!
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Pierre Hedary
                  </h5>
                  My name is Pierre Hedary and I operate a repair shop in
                  Titusville, Florida, that services classic Mercedes
                  exclusively.A large number of 300SEL 6.3s and 4.5s have passed
                  through our doors, all of which have need air suspension
                  repairs. Martin and his workshop are my single greatest asset.
                  In fact, without the work he is performing, these cars would
                  be impossible to sort and perfect, as the air suspension is
                  their defining attribute. While I frequently have to travel
                  the country to do follow up service on these cars, I have
                  never once had a major issue with any of his air valves, and
                  that says it all. <br /> <br />
                  4/6/2020
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Jay Leno
                  </h5>
                  Hello MARTIN, My name is Jay Leno and you recently did the
                  valves for my 6.3 and I just wanted to let you know what an
                  excellent job you did. It’s been a few months now and the car
                  has not dropped an inch, I don’t think they were that good
                  when they were new! I don’t know how long you have been around
                  Massachusetts but I used to work at Foreign motors in Boston
                  and used to dream of one day owning these cars. I also have a
                  600 that I will be sending you the valves for. It’s nice to
                  see German workmanship still being done by a improper German!
                  Talk to you soon, very satisfied customer, Jay Leno
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Simon Haynes
                  </h5>
                  We are one of the largest restoration companies based in the
                  UK specialising in Mercedes-Benz from the 1960’s 1970’s and
                  1980’s. Our Classic Department focuses on offering clients the
                  opportunity to restore, repair and maintain their
                  Mercedes-Benz to the very highest standards, as these vehicles
                  become older and in most cases more valuable, year on year it
                  becomes increasingly challenging to achieve and provide
                  quality and reliability. I am pleased that these demands are
                  always met when dealing with Martin from 600airsuspension.com
                  who we have used for many years now. Martin is very
                  knowledgeable, skilled and always comprehensively communicates
                  extremely well during whatever I would not hesitate to
                  recommend his services with complete confidence.
                  <br /> Simon @ John Haynes Auto Technics
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Herve Labesse
                  </h5>
                  In october 2017 I found a very nice mercedes w109 near San
                  Francisco California . The car was running fairly well at
                  first, but it started showing very rapidly that it was in need
                  of several overdue maintenances. One of them was a leaking air
                  suspension system.The car would stay up for a couple days and
                  start lowering itself on the front driver side and then pretty
                  soon it would end up resting on the tires.. After some
                  research on the internet i was able to locate
                  600airsuspension.com There were so much good recommendations
                  for Martin’s work and his full understanding of the system was
                  so clearly stated on his web site, that it was an obvious and
                  educated choice to have him rebuilt my complete system. The
                  car i purchased has had the air suspension gone through in the
                  past couple years, but the work was not documented and most
                  likely not done properly. I pulled the valves, anti freeze
                  unit , check valves and main valves and shipped them beginning
                  of march 2018. within a couple weeks they were rebuilt and
                  when i installed them back myself..the system performed
                  flawlessly. I had my air tank tinned inside out by Oregon re
                  tinners..so the system would be taken care for many years to
                  come. <br />
                  Needless to say that the valves were as new when they came
                  back with the proper plastic pins to be able to perform the
                  hight adjustments. So, wether you are in the continental
                  united states or in Europe and you own a w109 car..don’t waste
                  your time and money trying to do the work yourself or
                  entrusting it to your local mercedes mechanic.. send it to
                  Martin ..you will only have to do the work once..and will have
                  plenty of free time left ..to enjoy driving your car..
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Atle Naavik, Norway
                  </h5>
                  Hi Martin, Thanks a lot for your help with renovating the
                  valves and components in my 6,3. I got frustrated with my 6,3
                  that would sit down in just a matter of hours. Since I had
                  more issues with the suspension I decided to go all the way to
                  do a complete rebuild of the suspension. You did a magnificent
                  job with the valves. They works perfect and they look
                  beautiful. I really appreciate all the help you gave me and
                  how quickly you returned the valves. I also got a chance to
                  learn how the air system works, which has been very helpful.
                  Doing business with you has been a pleasure. I will highly
                  recommend you for any air suspension work on early Mercedes
                  cars.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Frank Knieriem, USA
                  </h5>
                  Hello Martin, I would like to thank you for the fantastic
                  service I received from you, dealing with my air suspension
                  issues on my 300SEL 6.3. I have to say that as a German, I
                  work like working with German engineers. My first conversation
                  with you, I already knew finally someone who knows what is
                  going on. I have a 1971 300SEL 6.3 which I purchased in Tokyo.
                  The car has really low miles but also has been sitting still
                  for 20 years. Of course the air-suspension was shut. The car
                  went up but at the same speed dropped again when we turned off
                  the engine. The airbags looked old, so we replaced the 4
                  airbags first. No change. We cleaned the valves and tank, or
                  so we thought. After installing everything again the car
                  decided to only go up in the front. I found you through a
                  video of another Mercedes shop on youtube. When I saw your
                  video, I knew already that you would be the only person to fix
                  my problem with the 6.3 Sure enough; you did exactly what you
                  promised. Fix my system. After you sent all the parts back now
                  7 weeks ago, we cleaned the lines as you suggested and put
                  everything back together. Martin, except for a small mishap
                  when we confused two lines, which you figured out for us it
                  was a smooth process. You actually took the time to figure out
                  what we did wrong, while you were in Germany. The car is now
                  up for 6 weeks. Any valve repair from now on, there will be
                  nobody else for me. As a matter of fact you are working now on
                  my Maserati Merak headlight valve. Who knew that it's the same
                  switch as the one on a Mercedes 600. Thank you for everything
                  you did for me. Have a great 2017. Best regards
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Jay Manek, UK
                  </h5>
                  Posted - 05/05/2014
                  <br />
                  <a href="http://www.m-100.cc/forum/topic.asp?TOPIC_ID=8083&SearchTerms=air,suspension">
                    http://www.m-100.cc/forum/topic.asp?TOPIC_ID=8083&SearchTerms=air,suspension
                  </a>{" "}
                  I think Martin is well known here.I have written this email to
                  him today and I think it should be shared. Dear Martin at 600
                  Air Suspension,I would like to thank you sincerely for the
                  absolutely outstanding service I received from you whilst
                  dealing with my Air Suspension Troubles on my 600. From the
                  first email I was entirely convinced of your knowledge and
                  attention to detail on this matter. You helped me diagnose my
                  problem remotely and then offered a solution at a price that I
                  was happy with.I am so afraid of dealing with sharks in the
                  classic car restoration world, I know of many who have lost
                  tens of thousands $ by entrusting their cars to the wrong
                  people.I was sent detailed step by step pictures and
                  commentary of my new air valves being restored which you
                  completed in a reasonable time frame. The exchange service
                  meant I didn't have to disassemble my car whilst you were
                  preparing new valves for me. Also I did not have risk my
                  valves getting lost with a courier!I feel this level of
                  customer service is lost nowadays in the world of fast and
                  cheap service. You have given me and my historic vehicle the
                  level of expertise that would make even the original designers
                  proud. My car is now riding as it should and no longer sinks
                  to the ground over night. As trivial as it may sound, It makes
                  me so proud to walk into my garage and see my car still raised
                  up as it should rather than slouched down looking sad. You
                  have not only gained a customer for life but a friend and I
                  hope to meet you some day in Boston. Now when are you going to
                  start repairing the hydraulic parts!? All the best.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Ruud, Netherlands W100:
                  </h5>
                  Posted - 09/09/2014
                  <br />
                  <a href="http://www.m-100.cc/forum/topic.asp?TOPIC_ID=7895&whichpage=3">
                    http://www.m-100.cc/forum/topic.asp?TOPIC_ID=7895&whichpage=3
                  </a>
                  <br /> After reading most of the posts here, and watching Mr.
                  Werminghausen on youtube, I thought it would be safe to send
                  my air suspension valves to Mr Werminghausen. The old ones
                  were not bad, but when the drain valve of my airtank was
                  leaking (took me some time to figure that out), the front of
                  my car would sink - most probably a leaking E-valve. I must
                  say, Mr.Werminghausen sent me a set perfectly overhauled air
                  valves. They look like new, but more importantly, there is no
                  leak any more, and consequently no sinking. Also - at first I
                  thought it was a matter of misperception - the steering and
                  cornering of the car feels better. The difference is not big
                  of course, but the car feels a bit more agile, and even a
                  little bit more comfortable. Finally, communication was great,
                  even though he lives in the US and I in Europe. Sending and
                  receiving the parts was simple and on schedule. I know that
                  there are more addresses for this, and probably good ones, but
                  from my own experience I can highly recommend Mr
                  Werminghausen. (And I didn't get a discount for writing this
                  :-)
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    John Heller, Atlanta GA
                  </h5>
                  The front air valves on my 1970 300SEL were leaking down
                  completely in less than 24 hours. These valves had been
                  rebuilt within the past 5 years but yet they were leaking down
                  again. I sent my air valves to Martin for him to diagnose and
                  rebuild. He quickly diagnosed the problem as an internal leak
                  with the E-valve. Once diagnosed he entirely rebuilt both
                  valves. He kept me informed via e-mail through the entire
                  process of diagnosing and rebuilding. The turn around time was
                  excellent and I had my air valves back within a week of him
                  receiving them. The rebuilt valves were returned in beautiful
                  condition and correctly adjusted to the proper Bosch/MB
                  specifications. I would highly recommend Martin in rebuilding
                  the Bosch/MB air valves.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Scott Melnick, CA
                  </h5>
                  Hi Martin Greetings from California! Just wanted to send along
                  a BIG THANK YOU for the help on my last 2 suspension problems.
                  Both the 600 SWB and the 6.3. Your expertise and quick repair
                  of the Air-Valves really helped. In the future, you'll be my
                  "go-to" guy for air suspension problems and help. Thanks again
                  Best Regards, Scott Melnick Auto Engineering/Classic
                  Mercedes-Benz
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12">
              <div class="card-panel grey lighten-5">
                <span class="black-text">
                  <h5>
                    Review By <br />
                    Kari Voutilainen, Helsinki, Finland
                  </h5>
                  I have a MB 300 SEL 4,5 that suffered from a leaking air
                  suspension. The problem kept getting worse all the time and in
                  the end the car was on her belly overnight. We managed to
                  point the leak to the left front valve levelling bar hindge.
                  Luckily I found Mr Werminghausen and his 600 Airsuspension on
                  Youtube and contacted him via email. Since my car was already
                  scheduled at the workshop Mr Werminghausen generously proposed
                  to send me one of his already reconditioned valves and refund
                  me as soon as he received my old leaking unit. We followed the
                  instructions found online at 600airsuspension.com and the fix
                  was a perfect success. My car is in the Finnish national
                  museum-car-registry, so it was imperative to use original
                  parts to solve the problem. Nothing could be more original
                  than the reconditioned original part. I am very happy with the
                  workmanship and service of Mr Werminghausen. All other old
                  valves seem to be air tight so far, but when the eventual
                  leaks appear I know who to call. I’m also considering a
                  complete overhaul of the entire system in advance, but I’m
                  having hard time convincing my engineer to change something
                  that is not broken - yet.
                  <br />
                  <br /> Kari Voutilainen
                  <br /> Helsinki, Finland
                  <br /> MB300 SEL 4,5 1972
                </span>
              </div>
            </div>
          </div>
          <h3>Return shipment of parts</h3>
          <p>
            These are valuable vintage parts. Cores received by the client for
            rebuilding are owned by the client, also for the duration of
            repairs. In case of a lost return box we cannot be made responsible
            for the loss of cores.
            <br />
            Please accept that we insure return shipment with an estimated core
            value plus rebuilding charges, in
            <br />
            agreement with the client.
            <br />
            The client may instruct us to ship with less insurance at client’s
            risk.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Airsuspension;
