import React from "react";
import emailjs from "emailjs-com";
require("dotenv").config();

export default function ContactUs() {
  // M.updateTextFields();
  // M.textareaAutoResize($('#textarea1'));

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_TAG1}`,
        `${process.env.REACT_APP_TAG2}`,
        e.target,
        `${process.env.REACT_APP_TAG3}`
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    document.getElementsByClassName("hidemail")[0].style.visibility = "hidden";
    document.getElementsByClassName("submitted")[0].textContent +=
      " Email sent Successfully, We will get back to you shortly";

    // document.getElementsByClassName("emailicon")[0].style.display = "block";
  }

  return (
    <div className="emailform">
      <div className="row">
        <form class="col s12" onSubmit={sendEmail}>
          <div class="row">
            <div class="input-field col s12">
              <input required type="text" name="name" />
              <label for="first_name"> Name</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input required type="email" name="email" />
              <label for="last_name">email</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <textarea
                required
                id="textarea1"
                name="message"
                class="materialize-textarea"
                length="500"
              ></textarea>
              <label for="textarea1">Message</label>
            </div>
          </div>
          <input type="submit" value="Submit!" className="btn blue" />
        </form>
      </div>
    </div>
  );
}
